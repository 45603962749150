var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.param.isApprContent
        ? _c(
            "div",
            { staticClass: "text-right" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable &&
                  _vm.educationInfo.documentStatusCd &&
                  _vm.educationInfo.documentStatusCd !== "ESC000001"
                    ? _c("c-qr-btn", {
                        attrs: {
                          mobileUrl:
                            "/sop/edu/result/educationResult?eduEducationId=" +
                            _vm.param.eduEducationId,
                        },
                      })
                    : _vm._e(),
                  _c("c-appr-btn", {
                    ref: "appr-btn",
                    attrs: {
                      editable: _vm.editable,
                      approvalInfo: _vm.approvalInfo,
                    },
                    on: {
                      beforeApprAction: _vm.approvalEducationInfo,
                      callbackApprAction: _vm.approvalEducationInfoCallback,
                      requestAfterAction: _vm.getDetail,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(_vm.component, {
        tag: "component",
        attrs: {
          param: _vm.param,
          educationInfo: _vm.educationInfo,
          planUpdateBtnData: _vm.planUpdateBtnData,
        },
        on: {
          "update:param": function ($event) {
            _vm.param = $event
          },
          "update:educationInfo": function ($event) {
            _vm.educationInfo = $event
          },
          "update:education-info": function ($event) {
            _vm.educationInfo = $event
          },
          "update:planUpdateBtnData": function ($event) {
            _vm.planUpdateBtnData = $event
          },
          "update:plan-update-btn-data": function ($event) {
            _vm.planUpdateBtnData = $event
          },
          setData: _vm.setData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }